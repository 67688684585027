import React, { useMemo } from 'react'
import mainStyles from '../../assets/css/App.module.scss'
import { Button, Loader } from '../../components'
import { Link } from 'react-router-dom'
import { StatusTd } from './utils'
import { FormComponent } from '../../shared'
import { useLinkDispatch, useLinkState } from '../../contexts/link'

export const NodeList = () => {
	const linkState = useLinkState()
	const linkDispatch = useLinkDispatch()

	const options = useMemo(() => {
		if (!linkState.isInitialized) return {}
		if (!linkState.data.selectedFunnel) return {}

		return linkState.data.link.nodes
			.filter(node => {
				return node.funnelId === linkState.data.selectedFunnel.value
			})
			.sort(a => {
				return a.status === 'active' ? -1 : 1
			})
	}, [linkState])

	const form = useMemo(() => {
		if (!linkState.isInitialized) return

		const options = {
			funnel: {
				label: 'Funnel Filter',
				props: {
					type: 'select',
					placeholder: 'Select...',
					value: linkState.data.selectedFunnel,
					onChange: item => {
						linkDispatch({
							type: 'update',
							payload: {
								data: {
									...linkState.data,
									selectedFunnel: item
								}
							}
						})
					}
				},
				options: linkState.data.link.funnels?.map(funnel => ({
					value: funnel.id,
					label: funnel.name
				}))
			}
		}

		const form = new FormComponent(options)

		return <>{form.renderInput('funnel')}</>
	}, [linkState.data.link.funnels, linkState])

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ width: '100%' }}>{form}</div>
				<div>
					<span style={{ float: 'right' }}>
						<Button size="small" to="/nodes">
							Create
						</Button>
					</span>
				</div>
			</div>
			{options && options.length ? (
				<table style={{ tableLayout: 'fixed', marginTop: '3rem' }}>
					<thead>
						<tr>
							<td>Name</td>
							<td>Type</td>
							<td>FunnelFlux ID</td>
						</tr>
					</thead>
					<tbody className={mainStyles.smallFont}>
						{options?.map(node => {
							return (
								<tr key={node.id}>
									<StatusTd status={node.status}>
										<Link className={mainStyles.link} to={`/nodes/${node.id}`}>
											{node.name}
										</Link>
									</StatusTd>
									<td className={mainStyles.noBreak}>{node.type}</td>
									<td className={mainStyles.noBreak}>{node.funnelflux_id}</td>
								</tr>
							)
						})}
						<tr />
					</tbody>
				</table>
			) : null}
			<Loader display={!linkState.isInitialized}></Loader>
		</>
	)
}
