import React, { useMemo, useState } from 'react'
import { Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Account/Account.module.scss'
import { FormComponent } from '../../shared'
import { LinkUtil } from '../../shared/link'
import history from '../../history'
import { useLinkState } from '../../contexts/link'
import { isEmpty } from 'lodash'

export const TrafficSource = ({ route }) => {
	const linkState = useLinkState()

	const [isEditing, setIsEditing] = useState(false)
	const [data, setData] = useState({
		name: '',
		token: '',
		funnelflux_id: '',
		tracking_fields: ''
	})
	const [isLoading, setIsLoading] = useState(false)

	async function preFetch() {
		const id = getTrafficSourceId()
		let returnData = {}
		setIsLoading(true)

		if (id) {
			setIsEditing(true)
			const trafficSource = await getTrafficSource(id)

			setData(trafficSource)
		}

		setIsLoading(false)

		return returnData
	}

	const getTrafficSourceId = () => {
		const id = route.match.params.id || null

		return !isNaN(id) ? parseInt(id) : null
	}

	const getTrafficSource = async id => {
		const linkUtil = new LinkUtil()

		const { status, data } = await linkUtil.getTrafficSource(id)

		if (status !== 200) {
			toastMessage(data.error, 'error')
			return
		}

		const { name, token, funnelflux_id, tracking_fields } = data.data

		return {
			name,
			token,
			funnelflux_id,
			tracking_fields
		}
	}

	function handleSave() {
		const linkUtil = new LinkUtil()
		const id = getTrafficSourceId()

		setIsLoading(true)

		if (
			Object.values(data)
				.map(value => value?.toString().trim())
				.some(isEmpty)
		) {
			toastMessage('Please fill the required fields...', 'error')
			setIsLoading(false)
			return
		}

		try {
			JSON.parse(data.tracking_fields)
		} catch (e) {
			toastMessage('Invalid JSON for tracking fields', 'error')
			setIsLoading(false)
			return
		}

		if (id) {
			linkUtil.updateTrafficSource(id, data).then(({ status, data }) => {
				setIsLoading(false)

				if (status !== 200) {
					toastMessage(data.error || 'An error has been occurred', 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Traffic source updated successfully', 'success')
				history.push('/products-funnels')
			})
		} else {
			linkUtil.createTrafficSource(data).then(({ status, data }) => {
				setIsLoading(false)

				if (status !== 200) {
					toastMessage(data.error, 'error')
					return
				}

				toastMessage('Traffic source created successfully', 'success')
				history.push('/products-funnels')
			})
		}
	}

	const form = useMemo(() => {
		const options = {
			name: {
				label: 'Name',
				props: {
					type: 'text',
					placeholder: 'Name',
					required: true,
					value: data.name,
					maxLength: 32,
					onChange: e => {
						setData({
							...data,
							name: e.target.value
						})
					}
				}
			},
			token: {
				label: 'Token',
				props: {
					type: 'text',
					placeholder: 'Token',
					required: true,
					maxLength: 8,
					value: data.token,
					onChange: e => {
						setData({
							...data,
							token: e.target.value
						})
					}
				}
			},
			funnelflux_id: {
				label: 'FunnelFlux ID',
				props: {
					type: 'text',
					placeholder: 'FunnelFlux ID',
					required: true,
					value: data.funnelflux_id,
					onKeyDown: e => {
						if (!e.key.match(/[0-9]+/) && e.key !== 'Backspace') {
							e.preventDefault()
						}
					},
					onChange: e => {
						setData({
							...data,
							funnelflux_id: e.target.value
						})
					}
				}
			},
			tracking_fields: {
				label: 'Tracking Fields',
				props: {
					type: 'textarea',
					placeholder: 'Tracking Fields',
					required: true,
					value: data.tracking_fields ?? '',
					onChange: e => {
						setData({
							...data,
							tracking_fields: e.target.value
						})
					}
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: handleSave
				}
			}
		}

		const form = new FormComponent(options)

		return (
			<>
				{form.renderInput('name')}
				{form.renderInput('token')}
				{form.renderInput('funnelflux_id')}
				{form.renderInput('tracking_fields')}
				{form.renderButton('save', { loading: isLoading })}
			</>
		)
	})

	return (
		<Page
			preFetch={preFetch}
			render={() => {
				if (!linkState?.isInitialized) {
					return <Loader display={true} />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								{isEditing ? (
									<h3>
										Editing Traffic Source #{getTrafficSourceId()}:{data.name}
									</h3>
								) : (
									<h3>Creating Traffic Source</h3>
								)}
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>{form}</div>
								</div>
							</div>
						</div>
						<Loader display={isLoading}></Loader>
					</>
				)
			}}
		></Page>
	)
}
