import React, { useEffect, useState } from 'react'
import { Loader, Page, toastMessage } from '../../components'
import mainStyles from '../../assets/css/App.module.scss'
import styles from '../Account/Account.module.scss'
import { FormComponent } from '../../shared'
import { LinkUtil } from '../../shared/link'
import history from '../../history'
import { useLinkState } from '../../contexts/link'
import { isEmpty } from 'lodash'

export const TrafficSourceAccount = ({ route }) => {
	const linkState = useLinkState()

	const [isEditing, setIsEditing] = useState(false)
	const [data, setData] = useState({
		traffic_source: linkState?.data?.selectedTrafficSource,
		name: '',
		account_identifier: ''
	})
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!linkState.isInitialized) return

		const id = getTrafficSourceAccountId()

		if (id) {
			setIsEditing(true)
			getTrafficSourceAccount(id).then(response => {
				setData(response)
				setIsLoading(false)
			})
		}
	}, [linkState.isInitialized])

	const getTrafficSourceAccountId = () => {
		const id = route.match.params.id || null

		return !isNaN(id) ? parseInt(id) : null
	}

	const getTrafficSourceAccount = async id => {
		const linkUtil = new LinkUtil()
		setIsLoading(true)

		const { status, data } = await linkUtil.getTrafficSourceAccount(id)

		if (status !== 200) {
			toastMessage(data.error, 'error')
			return
		}

		const { traffic_source_account_id, name, account_identifier, token } =
			data.data

		const trafficSource = findTrafficSource(token)

		return {
			traffic_source_account_id,
			name,
			account_identifier,
			traffic_source: trafficSource
		}
	}

	function handleSave() {
		const linkUtil = new LinkUtil()
		const id = getTrafficSourceAccountId()

		setIsLoading(true)

		if (
			Object.values(data)
				.map(value => value?.toString().trim())
				.some(isEmpty)
		) {
			toastMessage('Please fill the required fields...', 'error')
			setIsLoading(false)
			return
		}

		const payload = {
			traffic_source: data.traffic_source.value,
			name: data.name,
			account_identifier: data.account_identifier
		}

		if (id) {
			linkUtil
				.updateTrafficSourceAccount(id, payload)
				.then(({ status, data }) => {
					if (status !== 200) {
						toastMessage(data.error || 'An error has been occurred', 'error')
						setIsLoading(false)
						return
					}

					toastMessage('Traffic source account updated successfully', 'success')
					history.push('/products-funnels')
					setIsLoading(false)
				})
		} else {
			linkUtil.createTrafficSourceAccount(payload).then(({ status, data }) => {
				if (status !== 200) {
					toastMessage(data.message, 'error')
					setIsLoading(false)
					return
				}

				toastMessage('Traffic source account created successfully', 'success')
				history.push('/products-funnels')
				setIsLoading(false)
			})
		}
	}

	function findTrafficSource(token) {
		if (!token || !linkState.isInitialized) {
			return {}
		}
		const index = linkState.data.link.trafficSources?.findIndex(
			trafficSource => trafficSource.token === token
		)

		const trafficSource = linkState.data.link.trafficSources[index]

		return {
			value: trafficSource.token,
			label: trafficSource.name
		}
	}

	const form = () => {
		const options = {
			traffic_source: {
				label: 'Traffic Source',
				props: {
					type: 'select',
					placeholder: 'Select...',
					value: data.traffic_source,
					onChange: e => {
						setData({
							...data,
							traffic_source: e
						})
					}
				},
				options: linkState.data.link.trafficSources?.map(trafficSource => ({
					value: trafficSource.token,
					label: trafficSource.name
				}))
			},
			name: {
				label: 'Name',
				props: {
					type: 'text',
					placeholder: 'Name',
					value: data.name,
					maxLength: 32,
					onChange: e => {
						setData({
							...data,
							name: e.target.value
						})
					}
				}
			},
			account_identifier: {
				label: 'Account Identifier',
				props: {
					type: 'text',
					placeholder: 'Account Identifier',
					value: data.account_identifier,
					maxLength: 20,
					onChange: e => {
						setData({
							...data,
							account_identifier: e.target.value
						})
					}
				}
			},
			save: {
				label: 'Save',
				props: {
					type: 'button',
					onClick: handleSave
				}
			}
		}

		return new FormComponent(options)
	}

	return (
		<Page
			render={() => {
				if (!linkState?.isInitialized) {
					return <Loader display={true} />
				}

				return (
					<>
						<div className={mainStyles.container}>
							<div className={mainStyles.wrapper}>
								{isEditing ? (
									<h3>
										Editing Traffic Source Account #
										{getTrafficSourceAccountId()}:{data.name}
									</h3>
								) : (
									<h3>Creating Traffic Source Account</h3>
								)}
								<div className={styles.headingWrapper}>
									<div className={styles.leftSide}>
										{form().renderInput('traffic_source')}
										{form().renderInput('name')}
										{form().renderInput('account_identifier')}
										{form().renderButton('save', { loading: isLoading })}
									</div>
								</div>
							</div>
						</div>
						<Loader display={isLoading}></Loader>
					</>
				)
			}}
		></Page>
	)
}
