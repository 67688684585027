import { cloneDeep } from 'lodash'
import { RequestUtil } from './'

export class LinkUtil {
	createSource(data, linkDispatch, linkState) {
		return RequestUtil.post('/link/source', data).then(response => {
			const stateData = cloneDeep(linkState.data)

			stateData.link.sources.push({
				id: response.data.sourceId,
				nodeId: data.nodeId,
				source: data.source,
				channel: data.channel,
				params: data.params,
				accountIds: data.accountIds,
				mustBePersonalized: data.mustBePersonalized
			})

			linkDispatch({
				type: 'update',
				payload: {
					data: stateData
				}
			})
		})
	}

	deleteSource(sourceId, linkDispatch, linkState) {
		return RequestUtil.delete('/link/source', {
			sourceId
		}).then(() => {
			const stateData = cloneDeep(linkState.data)
			stateData.link.sources = stateData.link.sources.filter(
				source => source.id !== sourceId
			)

			linkDispatch({
				type: 'update',
				payload: {
					data: stateData
				}
			})
		})
	}

	getSources() {
		return RequestUtil.get('/link/source').then(response => response.data)
	}

	getProducts() {
		return RequestUtil.get('/product').then(response => response.data)
	}

	getFunnels() {
		return RequestUtil.get('/funnel').then(response => response.data)
	}

	getNodes() {
		return RequestUtil.get('/node').then(response => response.data)
	}

	getUrls() {
		return RequestUtil.get('/link/url').then(response => response.data)
	}

	getTrafficSources() {
		return RequestUtil.get('/traffic-source').then(response => response.data)
	}

	getAccounts() {
		return RequestUtil.get('/link/account').then(response => response.data)
	}

	getTrafficSource(id) {
		return RequestUtil.get(`/traffic-source/${id}`)
	}

	updateTrafficSource(id, data) {
		return RequestUtil.post(`/traffic-source/${id}`, data)
	}

	createTrafficSource(data) {
		return RequestUtil.post('/traffic-source', data)
	}

	getTrafficSourceAccounts(trafficSourceToken) {
		return RequestUtil.get(`/traffic-source-account`, {
			trafficSourceToken
		}).then(response => response.data)
	}

	getTrafficSourceAccount(id) {
		return RequestUtil.get(`/traffic-source-account/${id}`)
	}

	updateTrafficSourceAccount(id, data) {
		return RequestUtil.post(`/traffic-source-account/${id}`, data)
	}

	createTrafficSourceAccount(data) {
		return RequestUtil.post('/traffic-source-account', data)
	}

	getProduct(id) {
		return RequestUtil.get(`/product/${id}`)
	}

	updateProduct(id, data) {
		return RequestUtil.post(`/product/${id}`, data)
	}

	createProduct(data) {
		return RequestUtil.post('/product', data)
	}

	getFunnel(id) {
		return RequestUtil.get(`/funnel/${id}`)
	}

	updateFunnel(id, data) {
		return RequestUtil.post(`/funnel/${id}`, data)
	}

	createFunnel(data) {
		return RequestUtil.post('/funnel', data)
	}

	getNode(id) {
		return RequestUtil.get(`/node/${id}`)
	}

	updateNode(id, data) {
		return RequestUtil.post(`/node/${id}`, data)
	}

	createNode(data) {
		return RequestUtil.post('/node', data)
	}

	getFunnelFluxFunnels() {
		return RequestUtil.get('/funnelflux/funnel').then(response => response.data)
	}

	getFunnelFluxNodes(funnelId) {
		return RequestUtil.get(`/funnelflux/node/${funnelId}`)
	}
}
