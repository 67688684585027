import React from 'react'
import config from '../../config.json'
import styles from './ProductionWarningBanner.module.scss'

export const ProductionWarningBanner = () => {
	if (
		config.apiBaseUrl.includes('api.idiomusapp.com') &&
		window.location.hostname.includes('localhost')
	) {
		return (
			<div className={styles.productionWarningBanner}>
				<p>Beware: You are in production environment</p>
			</div>
		)
	}

	return null
}
